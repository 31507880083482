import { PropertyResponseItem, PropertyUnit } from '../components/search/models/property-search-response'

export const formatCadastreNumber = (number: string): string => {
    if (!number) return number
    return number.replaceAll('/0/0', '')
}

export const getMainPropertyCadastreNumber = (number: string | null): string => {
    if (!number) return ''
    const parts = number.split('/')
    //Added leased property number, so its considered a separate "main property"
    return `${parts[0]}/${parts[1]}/${parts[2] || 0}/${parts[3] || 0}`
}
export const isMainProperty = (number: string) => {
    if (!number) return false
    const parts = number.split('/')
    //return parts[2] === '0' && parts[3] === '0'
    //I removed part[2] check for leased proeprty and we consider leased propery as separete main
    return parts[3] === '0'
}

export const isSectionedPropertyParent = (cadastreNr: string) => {
    const parts = cadastreNr.split('/')
    if (!parts[2] || !parts[3]) return false
    return parts[2] === '0' && parts[3] === '0'
}

export const removeMunicipality = (number: string): string => {
    return number.replaceAll('0301-', '')
}

export const getMainProperty = (units: PropertyResponseItem[]) => {
    return units.find((u) => isMainProperty(u.cadastreNumber))
}
export const addLeadingZeros = (num: number): string => {
    return num.toString().padStart(4, '0')
}
export const createMatrikkelNumber = (input: string): string => {
    // Extract the municipality and the remaining parts of the input string
    const municipalityMatch = input.match(/^(\d{4})-(.*)$/)
    if (!municipalityMatch) {
        return ''
    }

    const [, municipality, remaining] = municipalityMatch
    const parts = remaining.match(/\d+/g) || []

    if (parts.length < 2) {
        return ''
    }

    const holdingNumber = parts.shift()
    const subHoldingNumber = parts.shift()
    const leaseNumber = parts.length > 0 ? parts.shift() : '0'
    const sectionNumber = parts.length > 0 ? parts.shift() : '0'

    return `${municipality}-${holdingNumber}/${subHoldingNumber}/${leaseNumber}/${sectionNumber}`
}

export const flattenAndMapHouseUnits = (units: PropertyResponseItem[]) => {
    return units
        .map((u) =>
            u.housingUnits.map((hu) => {
                return {
                    cadastreNumber: u.cadastreNumber,
                    housingUnits: [hu],
                    owners: hu.owners,
                }
            }),
        )
        .flat()
}

export const isPropertySectioned2 = (units: PropertyResponseItem[]): boolean => {
    const matrikkelNumbers = units.map((u) => u.cadastreNumber)
    if (
        matrikkelNumbers.some((m) => {
            const cadastreParts = m.split('/')
            return cadastreParts[3] !== '0'
        })
    ) {
        return true
    }
    return false
}

export const getAllAddresses = (units: PropertyResponseItem[]): string[] => {
    const address = units
        .map((u) =>
            u.housingUnits.filter((hu) => hu.address && hu.address.addressText).map((o) => o.address.addressText),
        )
        .flat()

    // @ts-ignore
    return [...new Set(address)]
}

export const normalizeCadastreNumber = (cadastreNumber: string): string => {
    return cadastreNumber.replaceAll('/', '-')
}
export const getStreetNames = (addresses: string[]) => {
    const streetNames: string[] = []
    const numberVariants: { [key: string]: string[] } = {}
    for (const address of addresses) {
        const lastSpaceIndex = address.lastIndexOf(' ')
        const street = address.substring(0, lastSpaceIndex)
        const numberVariant = address.substring(lastSpaceIndex + 1)
        if (!streetNames.includes(street)) {
            streetNames.push(street)
            numberVariants[street] = [numberVariant]
        } else {
            if (!numberVariants[street].includes(numberVariant)) {
                numberVariants[street].push(numberVariant)
            }
        }
    }
    return streetNames.map(
        (street) =>
            `${street} ${numberVariants[street]
                .sort((a, b) => {
                    const aNum = parseInt(a.replace(/\D/g, ''))
                    const bNum = parseInt(b.replace(/\D/g, ''))
                    if (aNum < bNum) {
                        return -1
                    } else if (aNum > bNum) {
                        return 1
                    } else {
                        return a.localeCompare(b)
                    }
                })
                .join(', ')}`,
    )
}

export const getAllOwners = (units: PropertyResponseItem[]): string[] => {
    return units.map((u) => u.owners.map((o) => o.name)).flat()
}

export const getAllDistinctPropertyBuildingNumbers = (units: PropertyResponseItem[]) => {
    const distinctBuildingNumbers = new Set<number>()

    units.forEach((unit) => {
        unit.housingUnits.forEach((housingUnit) => {
            distinctBuildingNumbers.add(housingUnit.buildingNumber)
        })
    })

    return Array.from(distinctBuildingNumbers)
}

export const getBestHousingUnit = (units: PropertyUnit[]): PropertyUnit => {
    const withAddress = units.find((u) => u.address && u.address.addressText)
    if (withAddress) {
        return withAddress
    }
    return {
        ...units[0],
        address: {
            addressText: '',
            postalCode: '',
            postalCodeName: '',
            residentialNumber: '',
        },
    }
}

export const getParentPropertyCadastreNr = (cadastre: string) => {
    const [root, titleNr] = cadastre.split('/')

    return `${root}/${titleNr}/0/0`
}
