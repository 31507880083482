import React from 'react'
import { Box, CircularProgress, Grid } from '@mui/material'
import { PropertyOverview } from 'components/property/shared/PropertyOverview'
import {
    flattenAndMapHouseUnits,
    getMainPropertyCadastreNumber,
    getParentPropertyCadastreNr,
} from 'utils/cadastre-utils'
import { PropertyType, PropertyUnit } from 'components/search/models/property-search-response'
import { useTranslation } from 'react-i18next'
import { useGetPropertyUnitsQuery } from 'components/search/search.service'
import { QueryStatusView } from 'components/general/query-status.view'
import { DialogUnitTable } from 'components/property/shared/DialogUnitTable'
import { HousingUnitProductsDialog } from 'components/property/shared/HousingUnitProductsDialog'

type SectionedPropertyChildViewProps = {
    cadastreNr: string
    propertyType: PropertyType
}

export const EmptySectionedPropertyChildView: React.FC<SectionedPropertyChildViewProps> = ({
    cadastreNr,
    propertyType,
}) => {
    const { t } = useTranslation()
    const parentCadastreNr = React.useMemo(() => getParentPropertyCadastreNr(cadastreNr), [cadastreNr])
    const sanitizedChildPropertyCadastreNr = getMainPropertyCadastreNumber(cadastreNr)

    const [selectedHousingUnit, setSelectedHousingUnit] = React.useState<null | PropertyUnit>(null)

    // this will return all the sectioned properties also
    const { data, isLoading, error, isError } = useGetPropertyUnitsQuery({
        cadastreNumber: parentCadastreNr,
    })

    const parentProperty = React.useMemo(
        () => data?.properties.find((property) => property.cadastreNumber === parentCadastreNr),
        [data?.properties, parentCadastreNr],
    ) // so that PropertyOverview doesnt rerender
    const memoizedEmptyOwners = React.useMemo(() => [], []) // so that PropertyOverview doesnt rerender
    const memoizedData = React.useMemo(() => (parentProperty ? [parentProperty] : []), [parentProperty]) // so that PropertyOverview doesnt rerender

    const handleOpenProductDialog = React.useCallback((property: PropertyUnit) => {
        setSelectedHousingUnit(property)
    }, [])

    if (isLoading)
        return (
            <Box height="100vh" width="100%" display="flex" alignItems="center" justifyContent="center">
                <CircularProgress size={52} />
            </Box>
        )
    if (isError) return <QueryStatusView error={error} isError={isError} />
    if (!parentProperty)
        return (
            <div>
                {t(
                    'property_not_found_report',
                    'We couldnt find the specific property, please report the cadastre number to customer support',
                )}
            </div>
        )

    const allHouseUnits = flattenAndMapHouseUnits([parentProperty])

    return (
        <>
            <Grid item xs={12}>
                <PropertyOverview
                    data={memoizedData}
                    isPropertySectioned={true}
                    sanitizedMainPropertyCadastreNr={sanitizedChildPropertyCadastreNr}
                    mainProperty={parentProperty}
                    allOwners={memoizedEmptyOwners}
                />
                <div>
                    <Box mt={2}>
                        <DialogUnitTable
                            units={allHouseUnits}
                            title={t('HOUSING_UNITS', 'Bruksenheter ({{huCount}})', {
                                huCount: allHouseUnits.length,
                            })}
                            onOpenProductDialog={handleOpenProductDialog}
                            type={propertyType}
                        />
                    </Box>
                </div>
            </Grid>
            <Grid item xs={12}></Grid>

            <HousingUnitProductsDialog
                isOpen={Boolean(selectedHousingUnit)}
                onClose={() => setSelectedHousingUnit(null)}
                housingUnit={selectedHousingUnit}
                mainPropertyMetaData={{
                    cadastreNumber: parentCadastreNr,
                    owners: [],
                }}
            />
        </>
    )
}
